<template>
  <div class="page">
    <div class="page-header">
      <div class="header-layer">
        <div class="header-title">
          <h3>Дүрийн мэдээлэл</h3>
          <span class="divider"></span>
          <p>Дүрийн мэдээлэл, тоглосон цувралууд</p>
        </div>

        <div class="header-breadcrumb">
          <router-link to="/">
            <span>Эхлэл</span>
            <i class="isax isax-arrow-right-4"></i>
          </router-link>

          <router-link to="/character/all">
            <span>Дүрүүд</span>
            <i class="isax isax-arrow-right-4"></i>
          </router-link>

          <router-link to="/character/all" class="current">
            <span>{{ character.name }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="actor-info">
      <div class="actor-info-panel">
        <div v-if="series.length > 0" class="actor-cover" :style="`background: url(${imageUrl(series[0].image)})`">
          <div class="cover-layer" :style="`background-color: ${character.color}; opacity: .3;`"></div>
        </div>
        <div class="actor-avatar-wrapper">
          <div class="actor-avatar" :style="`background-color: ${character.color};`" v-lazy:background-image="imageUrl(character.image, true)"/>
        </div>
        <div class="actor-info">
          <h3>{{ character.name }}</h3>
          <p v-html="character.description"></p>
        </div>
      </div>

      <div class="age-media-list media-list">
        <div v-if="isLoading" class="media-loader-wrap">
          <ProgressSpinner/>
        </div>
<!--        <div v-else class="media-grid-wrapper">-->
<!--          <div class="media-grid grid gap-4 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-2">-->
<!--            <a href="javascript:void(0)" v-for="item in series" :key="item.id" @click="handleClick(item.id)"-->
<!--               class="media-item">-->
<!--              <figure class="effect-sadie">-->
<!--                <img-->
<!--                    :src="Poster"-->
<!--                    v-lazy:background-image="imageUrl(item.image, true)"-->
<!--                    class="poster rounded-md"-->
<!--                />-->
<!--                <figcaption>-->
<!--                  &lt;!&ndash;              <h2>{{ item.title }}</h2>&ndash;&gt;-->
<!--                  <div class="item-actions">-->
<!--                    <a href="javascript:void(0)">-->
<!--                      <i class="pi pi-heart"></i>-->
<!--                    </a>-->

<!--                    <a v-if="item.age_limit" href="javascript:void(0)">-->
<!--                      <span>{{ item.age_limit }}+</span>-->
<!--                    </a>-->
<!--                  </div>-->

<!--                  <p>-->
<!--                    {{ item.title }}-->
<!--                  </p>-->
<!--                </figcaption>-->
<!--              </figure>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->

        <div v-else class="media-grid-wrapper">
          <div class="seeroo-grid grid gap-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 grid-cols-3 cs-style-3">
            <div v-for="item in series" :key="item.id" class="media-item">
              <router-link :to="`/theatre/detail/${item.id}`" class="media-cover">
                <a href="javascript:void(0)" class="card__play">
                  <i class="isax isax-play"></i>
                </a>
              </router-link>

              <span v-if="item.is_free" class="card__rate card__rate--green price">Үнэгүй</span>

              <img :src="Poster" v-lazy:background-image="imageUrl(item.poster_hz, true)"
                   class="poster rounded-md"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {imageUrl} from "../../utils";
import api from "../../store/_boot/baseApi"
import {setModalActive} from "../../store";
import Poster from "../../assets/poster-hz.png";

export default {
  components: {},
  setup() {

  },
  data() {
    return {
      isLoading: false,
      series: [],
      character: [],
      Poster
    }
  },

  computed: {},

  created() {
    this.getCharacters();
  },

  watch: {},

  methods: {
    imageUrl,
    handleClick(animeID) {
      this.$router.replace({query: {media: animeID}});
      setModalActive(true);
    },

    getCharacters() {
      this.isLoading = true;
      api.get(`/api/m/character/series/${this.$route.params.id}`).then(({data}) => {
        console.log('here');
        console.log(data);
        this.series = data.series;
        this.character = data.character;
        this.isLoading = false;
      })
    }
  }
};
</script>
<style>
</style>